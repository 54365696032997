import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { AdaptationAndRestore } from '../organisms/Razvod/AdaptationAndRestore'
import { CardsBlock } from '../organisms/Razvod/CardsBlock'
import { ConsultationRazvod } from '../organisms/Razvod/ConsultationRazvod'
import { FindYourSpecialist } from '../organisms/Razvod/FindYourSpecialist'
import { HelpChildren } from '../organisms/Razvod/HelpChildren'
import { HelpOfPsihologist } from '../organisms/Razvod/HelpOfPsihologist'
import { HeroBlock } from '../organisms/Razvod/HeroBlock'
import { HowItSection } from '../organisms/HowItSection'
import { ImportantUnderstanding } from '../organisms/Razvod/ImportantUnderstanding'
import { MentalStress } from '../organisms/Razvod/MentalStress'
import { OnlineConsultationYoutalk } from '../organisms/Razvod/OnlineConsultationYoutalk'
import { ProductMicromarkupComponent } from '../atoms/SEO/Micromarkup/Product'
import { PshychoOnlineHelp } from '../organisms/Razvod/PshychoOnlineHelp'
import { PsychologistsWithRazvod } from '../organisms/Razvod/PsychologistsWithRazvod'
import { RazvodIsImportant } from '../organisms/Razvod/RazvodIsImportant'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { StepsToFind } from '../organisms/Razvod/StepsToFind'
import { TariffBlock } from '../organisms/SeoTariffsAndBundles/TariffBlock'
import { TarrifPackages } from '../organisms/SeoTariffsAndBundles/TarrifPackages'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: var(--height);
  background: #dfebf9;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const Razvod = ({ data }) => (
  <App>
    <SEO
      description="Онлайн консультация психолога при разводе ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Психологическая помощь при разводе  онлайн — консультации психолога специалиста | YouTalk"
    >
      <ProductMicromarkupComponent name="Онлайн консультацияпсихолога при разводе" />
    </SEO>
    <Page>
      <Header />
      <Main>
        <HeroBlock />
        <RazvodIsImportant />
        <CardsBlock />
        <MentalStress />
        <ConsultationRazvod />
        <ImportantUnderstanding />
        <HelpOfPsihologist />
        <HelpChildren />
        <PshychoOnlineHelp />
        <AdaptationAndRestore />
        <OnlineConsultationYoutalk />

        <PsychologistsWithRazvod data={pick(['psychologists'], data)} />
        <TariffBlock />
        <TarrifPackages />
        <StepsToFind />
        <FindYourSpecialist />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default Razvod

export const query = graphql`
  query Razvod {
    psychologists: allPsychologistsData(
      filter: {
        work_areas: { elemMatch: { tag: { in: "Расставание, развод" } } }
        is_active: { eq: true }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
